import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";

const dialogStyle = {
  '& .MuiPaper-root': {width: '400px', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '100vw'},
  overflowX: "hidden",
};

export function DeleteAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("hr.department.Delete Department?")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Delete/>
      </IconButton>
    </Tooltip>
  )
}

export interface DeptDeleteProps {
  deptIds: Record<string, any>,
  dept: Record<string, any>,
}

const DepartmentDelete: React.FC<DeptDeleteProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <DeleteAction setInfo={setInfo}/>
      </Grid>
      <DeleteDeptDialog info={info} setInfo={setInfo} deptIds={props.deptIds} dept={props.dept}/>
    </Fragment>
  )
}

const DeleteDeptDialog = ({info, setInfo, deptIds, dept}: any) => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();
  const onSubmit = async (event: any) => {
    if (deptIds.length == 0) {
      SnackbarUtil.error(t("common.Select department first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    let error_info = '';
    dept.map((obj: any) => {
      if ('DEFAULT' === obj.code) {
        error_info = 'E0001'
      }
    })
    if (error_info === 'E0001') {
      SnackbarUtil.error(t("common.Default department cannot be deleted."), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return
    }
    try {
      const device_list = deptIds.map((dev: any) => {
        DepartmentService.destroy(dev.toString())
      })
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
    } catch (e) {
      console.log("error===", e)
    } finally {
      setInfo(false)
    }
  }
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("hr.department.Delete Department?")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Typography sx={{p: 3}}>
        {t('hr.department.Delete Confirm')}
      </Typography>
      <Grid style={{'padding': '20px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SaveButton onClick={onSubmit} children={t('common.confirm')}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default DepartmentDelete;
