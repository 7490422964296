import React, {Fragment, useCallback, useContext, useEffect, useRef, useState, useMemo} from "react";
import {GridColDef, GridFilterInputValueProps, GridRenderCellParams, GridValueGetterParams} from "@mui/x-data-grid";
import WebURL from "../../../../../urls";
import {
  Box,
  Checkbox,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useNavigate} from "react-router-dom";
import CustomDataGrid, {DataGridRef} from "@/components/data-grid/CustomDataGrid";
import {ATTEndpoints} from "@/services/att/endpoints";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarUtil from '@/utils/SnackbarUtil';
import moment from "moment/moment";
import TimecardService from "@/services/att/services/TimecardService";
import ExportAction from "@/components/actions/Export";
import {DataGridContext} from "@/components/data-grid/DataGridContext";
import Util from "@/utils/Util";

function isValidDate(date: any) {
  return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 2000
}

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '60vw', top: '-20vh'},
  '.MuiDialog-paper': {maxWidth: '100vw'},
};

const Timecard: React.FC = () => {
  const {t, i18n} = useTranslation();
  const [info, setInfo] = useState<boolean>(false);
  const today = new Date()
  const firstDay = new Date()
  firstDay.setDate(1)
  const [exportFormat, setExportFormat] = useState<string>("");
  const [searchKeywordText, setSearchKeywordText] = useState<string>("");
  const [pageValue, setPageValue] = useState<number>(0);
  const [pageSizeValue, setPageSizeValue] = useState<number>(10);
  const setFilter = (newValue: Record<any, any>) => {
    const {month} = newValue
    if (!month) {
      return
    }
    setExportFormat('')
    setFilterParams(newValue)
  }
  const [start_date, setStartDate] = useState<Date>(firstDay);
  const [end_date, setEndDate] = useState<Date>(new Date());
  const [selectValue, setSelectValue] = useState<boolean>(false);
  const ExtraAction = (props: any) => {
    // const dispatch = useContext(DataGridDispatchContext)
    const {searchKeyword} = useContext(DataGridContext)
    const task = useContext(DataGridContext)
    const {pageSize, page} = task
    // const handleRefresh = () => {
    //   dispatch({type: "refreshed"})
    // }
    return (
      <Fragment>
        <Grid item={true}>
          <ExportAction setExportFormat={(fileType: string) => {
            setSearchKeywordText(searchKeyword)
            setPageValue(page)
            setPageSizeValue(pageSize)
            // props.setFilterParams({...props?.filterParams, keyword: searchKeyword});
            props.setExportFormat(fileType);
          }}/>
        </Grid>
        <Grid item={true}>
          <Box>
            <Grid
              container={true}
              // columnSpacing={1}
              sx={{
                alignItems: "center",
                marginLeft: "5px"
              }}
            >
              <Box sx={{width: "150px"}}>
                <DatePicker
                  disableFuture
                  views={['year', 'month', 'day']}
                  value={start_date}
                  inputFormat={"dd-MM-yyyy"}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                    setFilterParams({
                      "abnormal_records": selectValue,
                      date_range: [
                        Util.datetime2string(newValue, "yyyy-MM-dd"),
                        Util.datetime2string(end_date, "yyyy-MM-dd")
                      ]
                    })
                    if (props.setFilterParams) {
                      props.setFilterParams({...props?.filterParams, ...{date_range: Util.datetime2string(newValue, "yyyy-MM-dd")}})
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      // fullWidth
                      size={"small"}
                      variant="outlined"
                      {...params}
                    />}
                />
              </Box>
              -
              <Box sx={{width: "150px"}}>
                <DatePicker
                  disableFuture
                  views={['year', 'month', 'day']}
                  value={end_date}
                  minDate={start_date}
                  inputFormat={"dd-MM-yyyy"}
                  onChange={(newValue: any) => {
                    setEndDate(newValue)
                    setFilterParams({
                      "abnormal_records": selectValue,
                      date_range: [
                        Util.datetime2string(start_date, "yyyy-MM-dd"),
                        Util.datetime2string(newValue, "yyyy-MM-dd")
                      ]
                    })
                    if (props.setFilterParams) {
                      props.setFilterParams({...props?.filterParams, ...{date_range: Util.datetime2string(newValue, "yyyy-MM-dd")}})
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      // fullWidth
                      size={"small"}
                      variant="outlined"
                      {...params}
                    />}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectValue}
                sx={{marginLeft: '20px'}}
                onChange={(event) => {
                  const new_value = event.target.checked
                  if (new_value) {
                    setSelectValue(true)
                  } else {
                    setSelectValue(false)
                  }
                  setFilterParams({
                    "abnormal_records": new_value,
                    "date_range": [
                      Util.datetime2string(start_date, "yyyy-MM-dd"),
                      Util.datetime2string(end_date, "yyyy-MM-dd")
                    ]
                  })
                }}/>
            }
            label={t("common.Display Only Abnormal Records")}
          />
        </Grid>
      </Fragment>
    )
  }
  useEffect(() => {
    if (exportFormat) {
      const export_meta = {
        fields: {
          employee_code: {
            alias: t("common.Person ID")
          },
          first_name: {
            alias: t("common.First Name")
          },
          last_name: {
            alias: t("common.Last Name")
          },
          att_date: {
            alias: t("common.Date")
          },
          timesheet_name: {
            alias: t("common.Timesheet")
          },
          clock_in: {
            alias: t("common.Clock In")
          },
          clock_out: {
            alias: t("common.Clock Out")
          },
          period_hrs: {
            alias: t("common.Clock Time(h)")
          },
          paid_break: {
            alias: t("common.Total Break Time(h)")
          },
          paid_work: {
            alias: t("common.Total Work Time(h)")
          },
          pairing: {
            alias: t("common.Statistic Rule Mode")
          },
          abnormal_situation: {
            alias: t("common.Abnormal Situation")
          }
        },
        title: t("common.Timecard Report"),
        file_type: exportFormat,
        share: []
      }
      if (!start_date || !end_date || !isValidDate(start_date) || !isValidDate(end_date)) {
        SnackbarUtil.error("The start date and end date are required, Please select the correct start and end dates",
          {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      } else {
        // setLoading(true)
        try {
          const data = export_meta;
          let params = ''
          if (Util.datetime2string(start_date, "yyyy-MM-dd") != Util.datetime2string(firstDay, "yyyy-MM-dd")
            || Util.datetime2string(end_date, "yyyy-MM-dd") != Util.datetime2string(new Date(), "yyyy-MM-dd")
            || selectValue || searchKeywordText) {
            setFilterParams({
              "abnormal_records": selectValue,
              date_range: [
                Util.datetime2string(start_date, "yyyy-MM-dd"),
                Util.datetime2string(end_date, "yyyy-MM-dd")
              ],
              "all": true
            })
            params = `date_range=${moment(start_date).format(
              'yyyy-MM-DD')}&date_range=${moment(end_date).format(
              'yyyy-MM-DD')}&keyword=${searchKeywordText}&abnormal_records=${selectValue}&all=${true}`;
          } else if ( pageValue != 0 || pageSizeValue != 10) {
            setFilterParams({
              "abnormal_records": selectValue,
              date_range: [
                Util.datetime2string(start_date, "yyyy-MM-dd"),
                Util.datetime2string(end_date, "yyyy-MM-dd")
              ],
              "all": false
            })
            params = `current=${pageValue + 1}&pageSize=${pageSizeValue}&date_range=${moment(start_date).format(
              'yyyy-MM-DD')}&date_range=${moment(end_date).format(
              'yyyy-MM-DD')}&keyword=${searchKeywordText}&abnormal_records=${selectValue}&all=${false}`;
          }
          else {
            setFilterParams({
              "abnormal_records": selectValue,
              date_range: [
                Util.datetime2string(start_date, "yyyy-MM-dd"),
                Util.datetime2string(end_date, "yyyy-MM-dd")
              ],
              "all": false
            })
            params = `current=${pageValue + 1}&pageSize=${pageSizeValue}&date_range=${moment(start_date).format(
              'yyyy-MM-DD')}&date_range=${moment(end_date).format(
              'yyyy-MM-DD')}&keyword=${searchKeywordText}&abnormal_records=${selectValue}&all=${false}`;
          }
          const res = TimecardService.download_data(params, data, "timecard").then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"], endings: "transparent"})
            const url = URL.createObjectURL(blob)
            const aLink = document.createElement("a")
            aLink.style.display = "none"
            aLink.href = url
            document.body.appendChild(aLink)
            aLink.click()
            URL.revokeObjectURL(url)
            document.body.removeChild(aLink)
            SnackbarUtil.success(t("common.success"));
          });
        } catch (e) {
          // console.log(e)
        } finally {
          // setLoading(false)
          // setUpload(false)
        }
      }
      setExportFormat('')
    }
  }, [exportFormat])

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [i18n.language])

  function debounce(fn: any, delay: any) {
    let timer: any
    return function (...args: any) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }

  const debouncedSave = debounce((newValue: any) => {
    // 这里是你的保存操作
    dataGridRef.current?.refresh();
  }, 1000);

  useEffect(() => {
    debouncedSave(selectValue)
  }, [selectValue, start_date, end_date])

  const defaultColumns: GridColDef[] = [
    {field: 'employee_name', headerName: t("hr.employee.Person Name"), width: 200, filterable: false},
    {field: 'employee_code', headerName: t("hr.employee.Person ID"), width: 130, filterable: false},
    {field: 'att_date', headerName: t("common.Date"), type: "date", width: 130, filterable: false},
    {field: 'timesheet_name', headerName: t("common.Timesheet"), filterable: false},
  ]

  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>();
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      firstDay.toISOString().slice(0, 10),
      today.toISOString().slice(0, 10)
    ]
  });

  const [gridColumn, setColumns] = useState<GridColDef[]>(() => {
    return [...defaultColumns];
  });

  const PairingField = (params: GridRenderCellParams) => {
    let pairing = "";
    switch (params.value) {
      case "First and Last":
        pairing = t("att.timeSheet.First and Last")
        break;
      case "Even and Odd":
        pairing = t("att.timeSheet.Even and Odd")
        break;
      default:
        break;
    }
    return (
      <Grid>
        {pairing}
      </Grid>
    )
  }

  const ExtendColumns = [
    {field: 'clock_in', headerName: t("common.Clock In"), filterable: false},
    {field: 'clock_out', headerName: t("common.Clock Out"), filterable: false},
    {field: 'period_hrs', headerName: t("common.Clock Time(h)"), filterable: false, width: 130},
    {field: 'paid_work', headerName: t("common.Total Work Time(h)"), filterable: false, width: 130},
    {field: 'paid_break', headerName: t("common.Total Break Time(h)"), filterable: false, width: 130},
    {
      field: 'pairing',
      headerName: t("common.Statistic Rule Mode"),
      renderCell: PairingField,
      filterable: false,
      width: 130
    },
    {field: 'abnormal_situation', headerName: t("common.Abnormal Situation"), filterable: false, width: 130},
  ]
  const processResponse = (data: any) => {
    const calcColumns: GridColDef[] = []
    let maxColumnsCount = 0;
    const result: any = [];
    if (data.data.length === 0) {
      calcColumns.push(...defaultColumns, ...ExtendColumns);
    } else {
      calcColumns.push(...defaultColumns);
      data.data.forEach((element: any) => {
        // get the largest column count
        const len = element.paired.length;
        maxColumnsCount = Math.max(maxColumnsCount, len);
        // uppack column data
        let paired_objs = {}
        element.paired.forEach((paired_obj: any) => {
          paired_objs = {...paired_objs, ...paired_obj};
        });
        result.push({...element, ...paired_objs})
      });
      // add dynamic column
      if (maxColumnsCount) {
        for (let i = 0; i < maxColumnsCount; i++) {
          calcColumns.push({field: `clock_in${i}`, headerName: t("common.Clock In"), filterable: false})
          calcColumns.push({field: `clock_out${i}`, headerName: t("common.Clock Out"), filterable: false})
          calcColumns.push({
            field: `period_hrs${i}`,
            headerName: t("common.Clock Time(h)"),
            filterable: false,
            width: 130
          })
        }
        calcColumns.push({
          field: 'paid_break',
          width: 130,
          headerName: t("common.Total Break Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.paid_break || "",
          filterable: false
        })
        calcColumns.push({
          field: 'paid_work',
          width: 130,
          headerName: t("common.Total Work Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.paid_work || "",
          filterable: false
        })
        calcColumns.push({
          field: 'pairing',
          headerName: t("common.Statistic Rule Mode"),
          minWidth: 160,
          renderCell: PairingField,
          filterable: false
        });
        calcColumns.push({
          field: 'abnormal_situation',
          headerName: t("common.Abnormal Situation"),
          minWidth: 160,
          filterable: false
        });
      } else {
        calcColumns.push(...ExtendColumns);
      }
    }
    setColumns(calcColumns);
    return {data: result, total: data.total};
  }
  const toolbar = {
    title: t("common.Timecard Management"),
    searchTips: t("common.Search by Person ID, Person Name, Timesheet Name"),
    search: true,
    export: true,
    info: {setDisplay: setInfo,},
    exportCallback: () => navigate(WebURL.ATT_TIMECARD_TIMECARD_EXPORT),
    breadcrumbs: [
      {breadcrumbName: t("common.Timecard Management"), path: WebURL.ATT_TIMECARD_TIMECARD}
    ],
    children: <ExtraAction filterParams={filterParams} setFilterParams={setFilter} setExportFormat={setExportFormat}/>
  }

  const InfoDialog = () => {
    return (
      <MuiDialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("common.Usage Tips")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
          <Box sx={{p: 1}}> 1. {t("dms.TimecardDataTip")}</Box>
          <Box sx={{p: 1}}> 2. {t("dms.TimecardDateTip")}</Box>
        </DialogContent>
      </MuiDialog>
    )
  }
  return (
    <>
      <Box
        sx={{
          '& .MuiDataGrid-row-height-no-limit': {
            maxHeight: '180px!important'
          },
        }}
      >
        <CustomDataGrid
          columns={gridColumn}
          ref={dataGridRef}
          uri={ATTEndpoints.ATT_TIMECARD_URI}
          requestParams={filterParams}
          // filterModel={filterDate}
          toolbar={toolbar}
          processResponse={processResponse}
          localeText={{
            filterPanelColumns: t("common.Columns"),
            filterPanelOperators: t("common.Operators"),
          }}
        />
      </Box>
      <InfoDialog/>
    </>
  )
}

export default Timecard;
